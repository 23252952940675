/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mydate-picker-range {
  width: 300px;
  min-width: 250px;
}
.mydate-picker-range .ant-calendar-range-picker-input {
  width: 125px;
}
.mobile .mydate-picker-range {
  width: 100%;
  max-width: 275px;
}
.mobile .mydate-picker-range .ant-calendar-range-picker-input {
  width: 110px;
  font-size: 0.9em;
}
.mobile .mydate-picker-range .ant-calendar-picker-clear {
  right: 6px;
}
