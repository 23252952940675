/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.part-name-valid {
  color: #1890ff;
}
.part-usage-table::v-deep {
  min-width: 700px;
}
.part-usage-table::v-deep th {
  text-align: center;
}
.part-usage-table::v-deep td.col-part-num {
  text-align: right;
}
.part-usage-table::v-deep .col-part-num {
  width: 80px;
}
.part-usage-table::v-deep .col-sku {
  width: 150px;
}
.mobile .part-usage-table::v-deep .ant-table,
.tablet .part-usage-table::v-deep .ant-table {
  font-size: 0.9em;
}
.mobile .part-usage-table::v-deep .ant-table .col-sku,
.tablet .part-usage-table::v-deep .ant-table .col-sku {
  width: 125px;
}
.mobile .part-usage-table::v-deep .ant-table .col-part-num,
.tablet .part-usage-table::v-deep .ant-table .col-part-num {
  width: 60px;
}
